import Vue from "vue";
import VueRouter from "vue-router";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/index.vue"),
    meta: {
      title: '数壤科技',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/productoverview",
    name: "productoverview",
    component: () => import("@/views/productOverview/index.vue"),
    meta: {
      title: '产品概览',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/class",
    name: "class",
    component: () => import("@/views/class/index.vue"),
    meta: {
      title: '数壤课堂',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/customerstories",
    name: "customerstories",
    component: () => import("@/views/customerStories/index.vue"),
    meta: {
      title: '客户案例',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/enterdigiland",
    name: "enterdigiland",
    component: () => import("@/views/enterDigiland/index.vue"),
    meta: {
      title: '走进数壤',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("@/views/partner/index.vue"),
    meta: {
      title: '数壤合伙人',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/agent",
    name: "agent",
    component: () => import("@/views/agent/index.vue"),
    meta: {
      title: '数壤代理商',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/h5/home",
    name: "Mhome",
    component: () => import("@/views/H5/home/index.vue"),
    meta: {
      title: '数壤科技',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/h5/productoverview",
    name: "Mproductoverview",
    component: () => import("@/views/H5/productoverview/index.vue"),
    meta: {
      title: '产品概览',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/h5/class",
    name: "Mclass",
    component: () => import("@/views/H5/class/index.vue"),
    meta: {
      title: '数壤课堂',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/h5/partner",
    name: "Mpartner",
    component: () => import("@/views/H5/partner/index.vue"),
    meta: {
      title: '数壤合伙人',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/h5/agent",
    name: "Magent",
    component: () => import("@/views/H5/agent/index.vue"),
    meta: {
      title: '数壤代理商',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/h5/customerstories",
    name: "Mcustomerstories",
    component: () => import("@/views/H5/customerstories/index.vue"),
    meta: {
      title: '客户案例',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
  {
    path: "/h5/enterDigiland",
    name: "MenterDigiland",
    component: () => import("@/views/H5/enterDigiland/index.vue"),
    meta: {
      title: '走进数壤',
      keywords: '数字厂长、数壤、数壤科技、数壤元宇宙、小报工、副厂长、数壤管家、数壤宝镜、科技金融双碳、能源双碳',
      description:'数壤科技（苏州）有限公司是专业的工业元宇宙系统和工业数字化转型平台服务商。公司拥有完全自主研发的全套软硬件和SaaS服务平台，对制造业中小企业进行快速数字化转型。进而打造以真实数据为底层，产业链为依托的工业元宇宙平台。帮助大量制造业客户实现价值，由内而外地提升企业数字化运营能力，迈入工业云宇宙时代。'
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
